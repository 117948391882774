<template>
    <div v-if="client">
        <h2 class="h2">{{ client.user.firstname }} {{ client.user.lastname }}</h2>

        <div v-if="this.$store.state.auth.user.role === 'agence'" class="mb-3">
            <router-link to="/agencies"><i class="fas fa-arrow-circle-left" /> Retour</router-link>
        </div>

        <div v-if="this.$store.state.auth.user.role === 'manager'" class="mb-3">
            <router-link to="/agency/managers"><i class="fas fa-arrow-circle-left" /> Retour</router-link>
        </div>

        <a
            href="#"
            class="rounded button is-warning rounded-lg text-decoration-none mr-1"
            @click.prevent="$refs.exportModal.show()"
        >
            Exporter les commandes
        </a>

        <div v-if="$store.state.contents.contents">
            <div class="d-flex mt-2">
                <div class="p-2 mr-4">
                    <h2 class="h2">Commandes</h2>
                </div>
                <div class="p-2">
                    <form class="form-inline">
                        <div class="form-group">
                            <label for="month">Période :</label>
                            <select id="month" v-model="month" class="form-control ml-2" @change="fetchData">
                                <option
                                    v-for="n in 12"
                                    :key="`month_${n}`"
                                    :value="
                                        moment()
                                            .subtract(n - 1, 'month')
                                            .startOf('month')
                                            .format('YYYY-MM-DD')
                                    "
                                >
                                    {{
                                        moment()
                                            .subtract(n - 1, 'month')
                                            .format('MMMM YYYY')
                                    }}
                                </option>
                            </select>
                        </div>
                    </form>
                </div>
            </div>

            <div class="box p-2">
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="h3">Par projet</h3>
                    </div>
                    <div
                        v-for="content in agencyClientContents.per_projects"
                        :key="content.id"
                        class="col-md-12 col-lg-6 col-xl-3"
                    >
                        <h5 class="h5">
                            {{ content.project.name }}
                        </h5>

                        <div class="row">
                            <div class="col-md-12">
                                <Balance
                                    :amount="content.contents_per_project_count"
                                    icon="fas fa-credit-card"
                                    subtitle="Nombre de commande"
                                    title=""
                                />
                            </div>
                            <div class="col-md-12">
                                <Balance
                                    :amount="content.contents_per_project_sum_price_paid"
                                    icon="fas fa-shopping-cart"
                                    subtitle="Budget dépensé"
                                    title=""
                                />
                            </div>
                            <div class="col-md-12">
                                <Balance
                                    :amount="
                                        content.project.budget > 0
                                            ? Number(
                                                  (content.contents_per_project_sum_price_paid /
                                                      content.project.budget) *
                                                      100
                                              ).toFixed(2) + '%'
                                            : 0 + '%'
                                    "
                                    icon="fas fa-percentage"
                                    subtitle="Budget dépensé / Budget autorisé"
                                    title=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ContentsTable :fetch-data="fetchData" />
        </div>

        <ExportOrdersModal ref="exportModal" />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import ContentsTable from '@/components/Agency/Table/ContentsTable'
    import ExportOrdersModal from '@/components/Agency/Modal/ExportOrdersModal'
    import Balance from '@/components/UI/Balance.vue'

    export default {
        title: 'Agence - Détails client',
        components: {
            Balance,
            ContentsTable,
            ExportOrdersModal
        },
        data: () => ({
            loading: true
        }),
        computed: {
            ...mapState('consultants', {
                client: 'agencyClient',
                agencyClientContents: 'agencyClientContents'
            }),
            ...mapState('contents', {
                config: 'contentsConfig'
            }),
            month: {
                get() {
                    return this.$store.state.consultants.month
                },
                set(value) {
                    this.$store.commit('consultants/setMonth', value)

                    this.config.filters['date_from'] = this.moment(value).startOf('month').format('YYYY-MM-DD')
                    this.config.filters['date_to'] = this.moment(value).endOf('month').format('YYYY-MM-DD')

                    this.fetchData()
                }
            }
        },
        created() {
            const agencyId = this.$route.query.agency
            const id = this.$route.query.id

            this.$store.commit('consultants/setAgency', { id: agencyId })

            this.getAgencyClient({ agency: agencyId, client: id })
                .then(() => {
                    this.fetchData()
                })
                .catch(e => {
                    this.$store.dispatch('toastError', e)
                    this.loading = false
                })
        },
        methods: {
            ...mapActions('consultants', ['getAgencyClient', 'getAgencyClientContents']),
            fetchData() {
                this.getAgencyClientContents({
                    agency: this.client.agency_id,
                    client: this.client.id,
                    params: this.$store.state.contents.contentsConfig
                })
                    .then(() => {
                        this.$store.commit('contents/setContents', this.agencyClientContents.contents)
                        this.$store.commit('contents/setProjects', this.client.user.projects)
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            onPageChange(page) {
                this.page = page
                this.fetchData()
            },
            onFilterChange(filters) {
                this.filters = filters
                this.fetchData()
            },
            onSortChange(field, order) {
                this.sort = field
                this.sortOrder = order
                this.fetchData()
            }
        },
        mounted() {}
    }
</script>
