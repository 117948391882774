var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.client)?_c('div',[_c('h2',{staticClass:"h2"},[_vm._v(_vm._s(_vm.client.user.firstname)+" "+_vm._s(_vm.client.user.lastname))]),(this.$store.state.auth.user.role === 'agence')?_c('div',{staticClass:"mb-3"},[_c('router-link',{attrs:{"to":"/agencies"}},[_c('i',{staticClass:"fas fa-arrow-circle-left"}),_vm._v(" Retour")])],1):_vm._e(),(this.$store.state.auth.user.role === 'manager')?_c('div',{staticClass:"mb-3"},[_c('router-link',{attrs:{"to":"/agency/managers"}},[_c('i',{staticClass:"fas fa-arrow-circle-left"}),_vm._v(" Retour")])],1):_vm._e(),_c('a',{staticClass:"rounded button is-warning rounded-lg text-decoration-none mr-1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$refs.exportModal.show()}}},[_vm._v(" Exporter les commandes ")]),(_vm.$store.state.contents.contents)?_c('div',[_c('div',{staticClass:"d-flex mt-2"},[_vm._m(0),_c('div',{staticClass:"p-2"},[_c('form',{staticClass:"form-inline"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"month"}},[_vm._v("Période :")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.month),expression:"month"}],staticClass:"form-control ml-2",attrs:{"id":"month"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.month=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.fetchData]}},_vm._l((12),function(n){return _c('option',{key:`month_${n}`,domProps:{"value":_vm.moment()
                                        .subtract(n - 1, 'month')
                                        .startOf('month')
                                        .format('YYYY-MM-DD')}},[_vm._v(" "+_vm._s(_vm.moment() .subtract(n - 1, 'month') .format('MMMM YYYY'))+" ")])}),0)])])])]),_c('div',{staticClass:"box p-2"},[_c('div',{staticClass:"row"},[_vm._m(1),_vm._l((_vm.agencyClientContents.per_projects),function(content){return _c('div',{key:content.id,staticClass:"col-md-12 col-lg-6 col-xl-3"},[_c('h5',{staticClass:"h5"},[_vm._v(" "+_vm._s(content.project.name)+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('Balance',{attrs:{"amount":content.contents_per_project_count,"icon":"fas fa-credit-card","subtitle":"Nombre de commande","title":""}})],1),_c('div',{staticClass:"col-md-12"},[_c('Balance',{attrs:{"amount":content.contents_per_project_sum_price_paid,"icon":"fas fa-shopping-cart","subtitle":"Budget dépensé","title":""}})],1),_c('div',{staticClass:"col-md-12"},[_c('Balance',{attrs:{"amount":content.project.budget > 0
                                        ? Number(
                                              (content.contents_per_project_sum_price_paid /
                                                  content.project.budget) *
                                                  100
                                          ).toFixed(2) + '%'
                                        : 0 + '%',"icon":"fas fa-percentage","subtitle":"Budget dépensé / Budget autorisé","title":""}})],1)])])})],2)]),_c('ContentsTable',{attrs:{"fetch-data":_vm.fetchData}})],1):_vm._e(),_c('ExportOrdersModal',{ref:"exportModal"})],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-2 mr-4"},[_c('h2',{staticClass:"h2"},[_vm._v("Commandes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('h3',{staticClass:"h3"},[_vm._v("Par projet")])])
}]

export { render, staticRenderFns }